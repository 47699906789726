import { graphql } from 'gatsby';
import React from 'react';
import { adaptKnowledges } from '@src/adapters/knowledge';
import { adaptParagraphs } from '@src/adapters/paragraph';
import Paragraph from '@src/components/paragraph';
import Knowledge from '@src/components/knowledge';
import ContactForm from '@src/components/contact-form';
import { useSiteMetadata } from '@src/hooks/site-metadata';
import LayoutFromContent from '@src/layouts/layout-from-content';
import { IndexQuery } from '@types';
import styles from './index.module.scss';

interface IndexProps {
  data: IndexQuery;
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  const { title, subtitle } = useSiteMetadata();

  const paragraphsData = adaptParagraphs(data.paragraphs);
  const knowledgesData = adaptKnowledges(data.knowledges);

  const presentationData = paragraphsData.find(e => e.id == 'presentation');
  const approcheData = paragraphsData.find(e => e.id == 'approche');
  const savoirfaireData = paragraphsData.find(e => e.id == 'savoirfaire');

  const heading = (
    <div className={styles.heading}>
      <div className={styles.titleBlock}>
        <h1>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </h1>
      </div>
      <div className={styles.mainKnowledges}>
        <h2 className={styles.mainKnowledge}>Ostéopathie</h2>
        <h2 className={styles.mainKnowledge}>Traitements naturels</h2>
        <h2 className={styles.mainKnowledge}>Homéopathie</h2>
      </div>
    </div>
  );

  return (
    <LayoutFromContent pageId="home" headerChildren={heading}>
      <div className={styles.page}>
        {presentationData && (
          <div className={styles.prez}>
            <div className={styles.content}>
              <Paragraph paragraph={presentationData} hLevel={2} />
            </div>
          </div>
        )}

        <div className={styles.sf}>
          <div className={styles.content}>
            {savoirfaireData && (
              <Paragraph paragraph={savoirfaireData} hLevel={2} />
            )}
            <section className={styles.sfList}>
              {knowledgesData.map(k => (
                <div key={k.id} className={styles.sfItem}>
                  <Knowledge knowledge={k} />
                </div>
              ))}
            </section>
          </div>
        </div>

        {approcheData && (
          <div className={styles.ap}>
            <div className={styles.content}>
              <Paragraph paragraph={approcheData} hLevel={2} />
            </div>
          </div>
        )}

        <ContactForm />
      </div>
    </LayoutFromContent>
  );
};

export const query = graphql`
  query Index {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: { id: { in: ["approche", "presentation", "savoirfaire"] } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            centered
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            right
            cta {
              cta_text
              cta_url
            }
          }
        }
      }
    }
    knowledges: allMarkdownRemark(
      filter: { fields: { collection: { eq: "knowledge" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            centered
            image {
              childImageSharp {
                fluid(maxWidth: 180, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
