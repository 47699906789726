import GatsbyImage from 'gatsby-image';
import React from 'react';
import { KnowledgeData } from '@types';
import styles from './knowledge.module.scss';
import { useSiteMetadata } from '@src/hooks/site-metadata';

type KnowledgeProps = {
  knowledge: KnowledgeData;
};

const Knowledge: React.FC<KnowledgeProps> = ({ knowledge }) => {
  const { title, subtitle } = useSiteMetadata();
  const picturePrefix = `${title} - ${subtitle} - `;

  return (
    <figure className={styles.knowledge}>
      <GatsbyImage
        className={styles.image}
        fluid={knowledge.image}
        alt={picturePrefix + knowledge.title}
      />
      <figcaption>
        <h3 className={styles.title}>{knowledge.title}</h3>
      </figcaption>
    </figure>
  );
};

export default Knowledge;
